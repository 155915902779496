.contain-card {
    display: flex;
    justify-content: center;
}

.img-pga {
    align-self: center;
    width: 200px;
    height: 100%;
}

.text-contain { 
    font-family: "Poppins"; 
    margin: 10px; 
    color: #4a4a4a;
    font-size: large;
}

.text-status {
    font-family: "Poppins";
    margin: 10px;
    font-weight: 500;
    font-size: large;
}

.btn-continue { 
    background-color: #f76b00; 
    border: none;
    width: 100%;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 18px !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 13px !important;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

@media screen and (max-width: 1024px) {
  .text-contain, .text-status {
    font-size: large
  } 
}

@media screen and (max-width: 768px) {
  .text-contain, .text-status {
    font-size: medium
  } 
  .img-pga {
    width: 150px;
    height: 100%;
  } 
}

@media screen and (max-width: 425px) {
  .text-contain, .text-status {
    font-size: small;
  }
  .img-pga {
    width: 150px;
    height: 100%;
  } 
}

@media screen and (max-width: 375px) {
  .text-contain, .text-status {
    font-size: smaller;
  }
  .img-pga {
    width: 120px;
    height: 100%;
  } 
}

@media screen and (max-width: 320px) {
  .text-contain, .text-status {
    font-size: xx-small;
  }
  .img-pga {
    width: 120px;
    height: 100%;
  }  
}